header {
  position: relative;
  z-index: 2;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.86) 68%,
    rgba(0, 136, 46, 0.75) 68%,
    rgba(0, 136, 46, 0.75) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.86) 68%,
    rgba(0, 136, 46, 0.75) 68%,
    rgba(0, 136, 46, 0.75) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.86) 68%,
    rgba(0, 136, 46, 0.75) 68%,
    rgba(0, 136, 46, 0.75) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

nav {
  padding: 0 15px !important;

  .navbar-brand {
    padding-bottom: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .navbar-nav {

    .nav-item {
      .nav-link {
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 0.875em;
        text-transform: uppercase;
        color: $black;
        padding: 0 30px;
        transition: all 0.2s;

        &:hover {
          color: $green;
          transition: all 0.2s;
        }

      }

      &.active {
        .nav-link {
          color: $green;
        }
      }
    }

  }

  .flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    // vertical-align: middle;
    justify-content: space-between;
    // align-items: flex-end;
    height: 100px;
    padding-top: 60px;
  }

  .language-dropdown {


    .lang-fr {
      .flag {
        background-image: url("../images/flag-fr.png");
        float: left;

      }
    }

    .lang-en {
      .flag {
        background-image: url("../images/flag-en.png");
        float: left;
      }
    }
    .lang-de {
      margin-top : 8px;
      .flag {
        background-image: url("../images/flag-de.png");
        float: left;
      }
    }

    .selector {

      content: "";
      background-image: url("../images/arrow-down.png");
      width: 20px;
      height: 20px;
      display: block;
      margin-left: 50px;
      background-repeat: no-repeat;
      background-position: center;


    }

    label,
    ul li {

      display: block;
      width: 50px;
      height: 20px;
      background: $white;
      padding: 0px 6px;
      cursor: pointer;
    }

    ul.lang-list {
      position: relative;
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      // padding: 0;

      li {

        &:last-child {
          border-bottom-right-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        &.selected {
          display: none;
        }
      }
    }

    label {
      position: relative;
      border-radius: 3px;
      z-index: 2;
    }

    .lang-flag,
    .lang-list .lang {
      .flag {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-size: 35px 35px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .lang-list{
      padding : 5px 0;
      background-color : $white;
    }

    &.open {
      ul.lang-list {
        visibility: visible;
        opacity: 1;

      }

      label {
        // @extend .shadow-2;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  .info-contact {
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    vertical-align: middle;
    padding-top: 30px;

    p {
      margin-left: 20px;
    }

    .icon {
      margin-right: 8px;
      font-size: 0.8em;
    }
  }
}

@media (max-width : 767.99px) {

  header {
    position: relative;
    z-index: 2;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.86) 78%,
      rgba(0, 136, 46, 0.75) 78%,
      rgba(0, 136, 46, 0.75) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.86) 78%,
      rgba(0, 136, 46, 0.75) 78%,
      rgba(0, 136, 46, 0.75) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.86) 78%,
      rgba(0, 136, 46, 0.75) 78%,
      rgba(0, 136, 46, 0.75) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
  }

  nav {

    .navbar-brand {
      img {
        width: 55%;
      }
    }

    .navbar-nav{
      .nav-item{
        padding : 10px 0;
        .nav-link{
          font-size  : 1.3em;
          padding-left : 15px;
          
        }
      }
    }

    .navbar-toggler {
      border: none;
    }

    .flex {
      padding: 15px 15px 30px 15px;
      height : 100%;

    }

    .info-contact {
      flex-direction: column;
      padding-top : 0;
    }

    .language-dropdown {
      .selector {
        content: "";
        background-image: url(../images/arrow-down.png);
        width: 13px;
        height: 22px;
        display: block;
        margin-left: 41px;
        background-repeat: no-repeat;
        background-position: center;
      }
      label{
        background : transparent;
      }
    }

    /*     .navbar-collapse {
      position: absolute;
      top: 98px;
      left: -103px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      width: 100vw;
      height: 100vh;

      background-color: $white;

    }

    .navbar-collapse.collapsing {
      height: auto;
      -webkit-transition: left 0.3s ease;
      -o-transition: left 0.3s ease;
      -moz-transition: left 0.3s ease;
      transition: left 0.3s ease;
      left: -160%;
    }

    .navbar-collapse.show {
      left: -103px;
      -webkit-transition: left 0.3s ease-in;
      -o-transition: left 0.3s ease-in;
      -moz-transition: left 0.3s ease-in;
      transition: left 0.3s ease-in;
    } */
  }
}