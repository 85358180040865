.slider-home {
    margin-top: -55px;
    z-index: 1;

    .owl-carousel {
        .item {
            height: 500px;
            position: relative;
            display: flex;
            align-items: center;



            h2 {
                font-family: "RobotoCondensed-Bold", sans-serif;
                color: $white;
                text-transform: uppercase;
                font-size: 4em;
                padding: 30px 30px 15px 30px;
            }

            .cross {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 32px;
                height: 32px;

                &:before,
                &:after {
                    position: absolute;
                    left: 15px;
                    content: ' ';
                    height: 33px;
                    width: 4px;
                    background-color: $white;
                }

                &:before {
                    transform: rotate(0deg);
                }

                &:after {
                    transform: rotate(90deg);
                }
            }



            p {
                color: $orange;
                font-family: "RobotoCondensed-BoldItalic", sans-serif;
                font-size: 1.4em;
                padding: 0 30px 15px 30px;
                text-transform: uppercase;
            }
        }




        .owl-dots {
            position: absolute;
            bottom: 30px;
            left: 15%;

            .owl-dot {
                border: 3px solid $white;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                span {
                    border-radius: 0;
                    background-color: transparent;
                    margin: 2px 2px;
                }

                &.active {
                    span {
                        background-color: $orange;

                    }
                }

            }
        }


    }
}

.categories-products {
    .list-products {
        list-style: none;
        display: -webkit-flex;
        /* Safari */
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        display: flex;
        flex-wrap: wrap;
        // width: 100%;
        padding: 0;

        .category {
            background-repeat: no-repeat;
            background-size: cover;
            width: 20%;
            height: auto;
            padding: 30px 30px;

            h3 {
                color: $white;
                text-transform: uppercase;
                font-family: 'RobotoCondensed-Bold';
                font-size: 1.4em;
                margin-bottom: 50px;
            }

            a {
                color: $orange;
                text-decoration: none;
                font-size: 1.2em;
                border: 2px solid $orange;
                padding: 5px 20px;
                // width : 50%;
                display: inline-block;
                transition: all 0.2s;


                &:hover {
                    background-color: $orange;
                    color: $white;
                    transition: all 0.2s;
                }
            }
        }
    }
}


.product-1,
.map {
    padding: 50px 15px;

    &.map-agents {
        .txt {
            text-align: center;

            h2 {
                margin-top: 15px;
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 30px;
                font-family: "Roboto-Regular", sans-serif;
                font-size: 1em;
                color: $black;
            }
        }
    }

    .txt {

        .icon-rouage,
        .icon-marker {
            font-size: 3.5em;
        }

        h1,
        h2 {
            font-family: 'RobotoCondensed-Bold', sans-serif;
            font-size: 1.8em;
            color: $black;
            padding-left: 30px;
            margin-bottom: 50px;

            .green {
                color: $green;

                &.uppercase {
                    text-transform: uppercase;
                }
            }
        }

        p {
            font-size: 1.1em;
            color: $black;
            font-family: 'Roboto-Regular', sans-serif;

            .orange {
                font-family: 'Roboto-Bold', sans-serif;
                color: $orange;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
        padding-top: 100px;
        max-width: 350px;
    }

    .part-search {
        padding-left: 0;

        .search {
            border: 8px solid $green !important;
            padding: 10px;
            width: 100%;
        }

        .search-list {
            list-style: none;
            padding: 0 0px;

            li {
                margin-bottom: 15px;
                padding: 15px 15px;
                border-bottom: 1px solid $very-light-green;

                .flex-space,
                .txt-left,
                .txt-right {
                    p {
                        margin-bottom: 5px;
                    }

                    .pays {
                        font-family: 'RobotoCondensed-Bold';
                        font-size: 1.2em;
                        color: $green;

                    }

                    .city,
                    .sous-rep,
                    .mail {
                        font-family: 'Roboto-Regular', sans-serif;
                        font-size: 0.95em;
                        color: $black;

                    }

                    .sous-rep {
                        text-transform: uppercase;
                    }

                    .rep,
                    .contact {
                        font-family: 'Roboto-Bold', sans-serif;
                        font-size: 0.95em;
                        color: #848484;
                        padding-bottom: 0;
                    }
                }

                .flex-space {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                .txt-left {
                    text-align: left;
                }

                .txt-right {
                    text-align: right;
                }
            }
        }
    }

    .back-map {
        background-color: $very-light-green;
        overflow: hidden;
        padding : 0;
        .icon-corner-top {
            position: absolute;
            top: -4px;
            left: -3px;
            border-top: 6px solid $green;
            width: 6%;
            height: 10%;
            z-index: 10;
        }

        .icon-corner-left {
            position: absolute;
            top: -4px;
            left: -4px;
            border-left: 6px solid $green;
            width: 10px;
            height: 8%;
            z-index: 10;
        }

        #chartdiv {
            width: 100%;
            height: 425px;


            [aria-labelledby=id-47-title] {
                display: none;
            }

            [aria-describedby] {
                color: #ffffff;
            }
        }

        .map-marker {
            /* adjusting for the marker dimensions
            so that it is centered on coordinates */
            margin-left: -8px;
            margin-top: -8px;
        }

        .map-marker.map-clickable {
            cursor: pointer;
        }

        .pulse {
            width: 30px;
            height: 30px;
            background: url("../images/marker-map.svg");
            z-index: 10;
            position: absolute;
            background-repeat: no-repeat;
            

            &:hover {

                background: url("../images/marker-map-hover.svg");
                background-repeat: no-repeat;


            }
        }

        .map-marker .dot {
            background: transparent;
            -webkit-border-radius: 60px;
            -moz-border-radius: 60px;
            border-radius: 60px;
            height: 50px;
            width: 50px;
            position: absolute;
            top: -20px;
            left: -20px;
            z-index: 1;
            opacity: 0;
        }

        .tooltip {
            width: 50px;
            height: 50px;

        }

    }
}

.slider-product {

    text-align: center;
    padding: 50px 15px;

    .icon-cube {
        font-size: 3.5em;

    }

    h2 {
        color: $white;
        font-size: 2em;
        font-family: 'RobotoCondensed-Bold', sans-serif;
        margin: 10px 0 35px 0;
    }

    .owl-item>div {
        cursor: pointer;
        margin: 6% 8%;
        transition: margin 0.4s ease;
        width: 100%;
    }

    .owl-item.center>div {
        cursor: auto;
        margin: 0;
        width: 100%;
    }

    .owl-item:not(.center)>div {
        opacity: .75;
    }

    .owl-nav {
        display: block !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%);

        button {
            position: relative;
            left: -50%;


            &.owl-next {
                margin-left: 300px;

            }

            &.owl-next,
            &.owl-prev {
                font-size: 2em;
                color: $green;
                background-color: $white;
                border-radius: 50%;
                padding: 0px 20px 6px 20px !important;

            }
        }
    }

    .center {
        .item {
            border: 10px solid $white;
            padding: 30px;
            border-radius: 50%;
        }

    }

    .item {
        padding: 50px;
    }
}

@media (max-width : 1199.99px) {
    .slider-product {
        .owl-nav {
            display: none !important;
        }
    }
}


@media (max-width : 991.99px) {
    .categories-products {
        .list-products {

            .category {
                background-repeat: no-repeat;
                background-size: cover;
                width: 50%;



            }
        }
    }
}

@media (max-width : 767.99px) {
    .slider-home {
        margin-top: 0;
    }

    .slider-product {




        .owl-item>div {
            cursor: pointer;
            margin: 6% 8%;
            transition: margin 0.4s ease;
            width: 50%;
        }

        .owl-item.center>div {
            cursor: auto;
            margin: auto;
            width: 40%;
        }

        .owl-item:not(.center)>div {
            opacity: .75;
        }



        .center {
            .item {
                border: 10px solid $white;
                padding: 30px;
                border-radius: 50%;
            }

        }

        .item {
            padding: 50px;
        }
    }
    .product-1,
.map {
    padding: 50px 15px;

    &.map-agents {
        padding: 0 15px 50px 15px;
        .txt {
            text-align: center;

            h2 {
                margin-top: 15px;
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 30px;
                font-family: "Roboto-Regular", sans-serif;
                font-size: 1em;
                color: $black;
            }
        }
    }

    .txt {

        .icon-rouage,
        .icon-marker {
            font-size: 3.5em;
        }

        h1,
        h2 {
            font-family: 'RobotoCondensed-Bold', sans-serif;
            font-size: 1.8em;
            color: $black;
            padding-left: 30px;
            margin-bottom: 50px;

            .green {
                color: $green;

                &.uppercase {
                    text-transform: uppercase;
                }
            }
        }

        p {
            font-size: 1.1em;
            color: $black;
            font-family: 'Roboto-Regular', sans-serif;

            .orange {
                font-family: 'Roboto-Bold', sans-serif;
                color: $orange;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
        padding-top: 100px;
        max-width: 350px;
    }

    .part-search {
        padding-left: 0;

        .search {
            border: 8px solid $green !important;
            padding: 10px;
            width: 100%;
        }

        .search-list {
            list-style: none;
            padding: 0 0px;

            li {
                margin-bottom: 15px;
                padding: 15px 15px;
                border-bottom: 1px solid $very-light-green;
                cursor : pointer;
                &:last-child{
                    border: none;
                }
                .flex-space,
                .txt-left,
                .txt-right {
                    p {
                        margin-bottom: 5px;
                    }

                    .pays {
                        font-family: 'RobotoCondensed-Bold';
                        font-size: 1.2em;
                        color: $green;

                    }

                    .city,
                    .sous-rep,
                    .mail {
                        font-family: 'Roboto-Regular', sans-serif;
                        font-size: 0.95em;
                        color: $black;

                    }

                    .sous-rep {
                        text-transform: uppercase;
                    }

                    .rep,
                    .contact {
                        font-family: 'Roboto-Bold', sans-serif;
                        font-size: 0.95em;
                        color: #848484;
                        padding-bottom: 0;
                    }
                }

                .flex-space {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                .txt-left {
                    text-align: left;
                }

                .txt-right {
                    text-align: right;
                }
            }
        }
    }

    .back-map {
        margin-bottom : 30px;


    }
}
}

@media (max-width : 575.99px) {
    .slider-home {
        // margin-top: -32px;
        z-index: 1;

        .owl-carousel {
            .item {

                h2 {
                    font-size: 2em;
                    padding: 30px 30px 15px 30px;
                }

                p {

                    font-size: 1.4em;
                    padding: 0 0px 15px 0px;

                }
            }

        }
    }

    .categories-products {
        .list-products {

            .category {
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;



            }
        }
    }

    .product-1,
    .map {
        padding: 50px 15px;

        .txt {
            text-align: center;

            .icon-rouage,
            .icon-marker {
                font-size: 3.5em;
            }

            h1,
            h2 {
                font-family: 'RobotoCondensed-Bold', sans-serif;
                font-size: 1.8em;
                color: $black;
                padding-left: 30px;
                margin-bottom: 50px;

                .green {
                    color: $green;

                    &.uppercase {
                        text-transform: uppercase;
                    }
                }
            }

            p {
                font-size: 1.1em;
                color: $black;
                font-family: 'Roboto-Regular', sans-serif;

                .orange {
                    font-family: 'Roboto-Bold', sans-serif;
                    color: $orange;
                }
            }
        }




    }


    .slider-product {
        .owl-item>div {
            cursor: pointer;
            margin: 6% 8%;
            transition: margin 0.4s ease;
            width: 50%;
        }

        .owl-item.center>div {
            cursor: auto;
            margin: auto;
            width: 50%;
        }

        .owl-item:not(.center)>div {
            opacity: .75;
        }



        .center {
            .item {
                border: 10px solid $white;
                padding: 30px;
                border-radius: 50%;
            }

        }

        .item {
            padding: 50px;
        }
    }
}

.tooltip-inner {
    background-color: $white;
    color: $black;
    -webkit-box-shadow: 2px 13px 71px 0px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 2px 13px 71px 0px rgba(0, 0, 0, 0.23);
    box-shadow: 2px 13px 71px 0px rgba(0, 0, 0, 0.23);
    text-align: left;
    padding: 15px;
}

.arrow {
    &:before {
        border-top-color: $white !important;
    }

}

.zoomTest{
    cursor: pointer;
}