.product {
    .preview {

        .icon-corner-top {
            position: absolute;
            top: -4px;
            left: -3px;
            border-top: 6px solid $green;
            width: 10%;
            height: 10%;
        }

        .icon-corner-left {
            position: absolute;
            top: -4px;
            left: -4px;
            border-left: 6px solid $green;
            width: 10px;
            height: 10%;
        }

        .icon-corner-bottom {
            position: absolute;
            bottom: -4px;
            right: -3px;
            border-bottom: 6px solid $green;
            width: 10%;
            height: 10%;
        }

        .icon-corner-right {
            position: absolute;
            bottom: -4px;
            right: -4px;
            border-right: 6px solid $green;
            width: 10px;
            height: 10%;
        }

        border: 2px solid $green;
        background-color: $light-grey;
        position: relative;
        margin-bottom: 30px;
        height: 500px;

        .preview-img {
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .icon-loop {
            position: absolute;
            font-size: 7em;
            color: rgba($color: $green, $alpha: 0.8);
            top: 50%;
            transform: translateY(-40%);
            left: 50%;
            transform: translateX(-50%);
            margin-top: -54px
        }
    }


    .img {
        border: 2px solid $green;
        background-color: $light-grey;

        img {
            padding: 10px;
        }
    }

    h2 {
        font-family: 'RobotoCondensed-Bold', sans-serif;
        color: $green;
        font-size: 2em;
    }

    p {
        font-family: 'RobotoCondensed-Regular', sans-serif;
        color: $black;
        font-size: 1.1em;
    }


    .nav-product {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding-left: 0;
        list-style: none;
        border-bottom: 1px solid $very-light-green;

        .item {
            padding: 10px 0px 10px 0;
            font-family: 'RobotoCondensed-Bold', sans-serif;
            font-size: 1.15em;

            &.active {
                border-bottom: 3px solid $green;

                .link {
                    color: $green;
                }
            }
        }

        .link {
            position: relative;
            text-transform: uppercase;
            font-size: 1em;
            color: $black;
            padding: 18px;
            cursor: pointer;


        }
    }

    .conteneur-fonctionnement {
        ul {
            font-family: 'RobotoCondensed-Regular', sans-serif;
            font-size: 1.1em;
            color: $green;
            padding-top: 30px;
            list-style: none;
            list-style-position: outside;
            list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAK0lEQVQoU2NkQAcduv/BQhWXGZGlUDhgCToqhFmF4VhUAUa4mwgqHPy+BgAJqRtzyLd0egAAAABJRU5ErkJggg==');

        }
    }

    .conteneur-caracteristiques,
    .caracteristiques,
        {

        display: none;



        table {
            width: 100%;
            margin: 50px 0;
            border: 2px solid $green;

            thead {
                font-size: 1.1em;
                text-align: left;
                vertical-align: bottom;

                tr {


                    th {
                        font-family: 'RobotoCondensed-Bold', sans-serif;
                        font-size: 1.1em;
                        color: $green;
                        padding: 12px 10px 8px 10px;

                        &:first-child {
                            border-right: 1px solid $very-light-green;
                        }

                    }
                }
            }

            tbody {
                td {
                    height: 32px;
                    padding: 10px 15px;

                    color: $black;
                    border: 1px solid $very-light-green;
                    font-family: 'RobotoCondensed-Bold', sans-serif;
                    font-size: 1em;
                }
            }
        }
    }
}

@media (max-width : 767.99px) {
    .product {

        .conteneur-caracteristiques,
        .caracteristiques {
            display: none;
            overflow: hidden;

            ul {
                font-size: 1em;
                padding-top: 30px;
            }

            table {
                width: 100%;
                margin: 50px 0;
                display: flex;
                overflow: auto;

                thead {
                    font-size: 0.8em;
                    //   text-align: left;
                    vertical-align: bottom;
                    text-align: center;

                    tr {
                        display: flex;
                        flex-direction: column;

                        th {
                            height: 40px;
                            padding: 12px 10px 8px 10px;
                        }
                    }
                }

                tbody {
                    display: flex;

                    tr {
                        display: flex;
                        flex-direction: column;
                    }

                    td {
                        height: 40px;
                        padding: 10px 0px;
                        text-align: center;
                        font-size: 0.8em;

                    }
                }
            }
        }
    }
}