.header-page {
    margin-top: -55px;
    position: relative;


    h1 {
        color: $white;
        font-family: 'RobotoCondensed-Bold', sans-serif;
        text-transform: uppercase;
        font-size: 3.3em;
        margin-top: 160px;

        &:before {
            content: "\e901";
            font-family: 'icomoon';
            font-size: 0.8em;
            margin-right: 20px;
        }

        &.product{
            
            &:before{
                content:'';
            }
        }

    }
}

.breadcrumb-container {
    background-color: rgba($color: $dark-blue, $alpha: 0.1);

    .breadcrumb {
        padding: 20px 15px;
        margin-bottom: 0;

        li {
            color: $black;
            font-family: 'Roboto-Regular', sans-serif;
            font-size: 1.1em;

            a {
                color: $black;
                text-decoration: none;

                &:after {
                    content: "\e90a";
                    color: $green;
                    margin-right: 10px;
                    margin-left: 10px;
                    font-family: 'icomoon';
                    font-size: 0.7em;
                }
            }


            &:last-child {
                font-family: 'Roboto-Bold', sans-serif;

                a {
                    color: $green;

                    &:after {
                        content: '';
                    }
                }

            }
        }
    }
}
.link-back, .link-product {
    margin: 50px 0;

    &.link-product{
        display: flex;
        justify-content: space-between;
    }

    a {
        color: $black;
        border: 1px solid $green;
        border-radius: 30px;
        padding: 10px 15px;
        transition: all 0.2s;

        &:hover {
            color: $green;
            transition: all 0.2s;
            text-decoration: none;

        }

        span {
            margin-right: 15px;

            &.icon-dl:before{
               color : $green;
            }
        }
    }
}

.listing-products {

    .toolbar,
    .catalogue {
        text-align: right;

        a {
            display: block;
            text-transform: uppercase;
            font-family: 'RobotoCondensed-Bold', sans-serif;
            color: $black;
            font-size: 1.3em;
            padding: 10px 25px 10px 0;
            border-right: 2px solid $black;
            cursor: pointer;
            padding-right: 25px;
            transition: all 0.2s;
            text-decoration : none;

            &.active {
                color: $orange;
                border-color: $orange;
            }

            &:hover {
                color: $orange;
                border-color: $orange;
                transition: all 0.2s;
            }
        }
    }

    .catalogue {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;

        a {
            color: $orange;
            border-color: $orange;
            

        }

        .icon-dl {
            font-size: 3.5em;
            padding-top: 10px;

        }
    }

    #list-products {
        list-style: none;
        display: -webkit-flex;
        -webkit-flex-wrap: wrap;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        .one-product {
            margin: 10px;

            .background {

                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 250px;
                height: 240px;
                display: flex;



                .txt {
                    background-color: rgba($color: $dark-blue, $alpha: 0.5);
                    align-self: flex-end;
                    padding: 20px;
                    width: 100%;

                    h2 {
                        color: $white;
                        font-family: 'RobotoCondensed-Bold', sans-serif;
                        font-size: 1.3em;
                        text-transform: uppercase;
                    }




                    .content-product {
                        display: none;
                        color: $white;
                        font-family: 'Roboto-Regular', sans-serif;
                        font-size: 1.05em;
                        transition: all 0.2s;

                    }
                }
            }

            &:hover {
                .background {
                    .txt {
                        .content-product {
                            display: block;
                            transition: all 0.2s;
                        }
                    }
                }
            }

            .button {
                width: 100%;
                background-color: $dark-blue;
                padding: 20px;
                margin-top: -2px;
                display : flex;
                justify-content: center;

                .details {
                    border: 1px solid $orange;
                    padding: 5px 20px;
                    background: none;
                    text-transform: uppercase;
                    color: $orange;
                    display: inline-block;
                    margin: auto;
                    transition: all 0.2s;
                    text-decoration : none;

                    &.active,
                    &:hover {
                        background-color: $orange;
                        color: $white;
                        transition: all 0.2s;
                    }
                }
            }
        }
    }
}


@media (max-width : 767.99px) {
    .listing-products {

        .toolbar,
        .catalogue {
            text-align: center;

            a {
                display: block;
                text-transform: uppercase;
                font-family: 'RobotoCondensed-Bold', sans-serif;
                color: $black;
                font-size: 1.3em;
                padding: 10px 25px 10px 0;
                border-right: none;
                cursor: pointer;
                padding-right: 25px;
                transition: all 0.2s;

                &.active {
                    color: $orange;
                    border-color: $orange;
                }

                &:hover {
                    color: $orange;
                    border-color: $orange;
                    transition: all 0.2s;
                }
            }
        }

        .catalogue {
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;


            a {
                color: $orange;
                border-color: $orange;

            }

            .icon-dl {
                font-size: 1.3em;
                padding-top: 13px;
                margin-right: 10px;

            }
        }

        #list-products {
            list-style: none;
            display: -webkit-flex;
            -webkit-flex-wrap: wrap;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0;

            .one-product {
                margin: 10px;

                .background {

                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    width: 250px;
                    height: 240px;
                    display: flex;



                    .txt {
                        background-color: rgba($color: $dark-blue, $alpha: 0.5);
                        align-self: flex-end;
                        padding: 20px;
                        width: 100%;

                        h2 {
                            color: $white;
                            font-family: 'RobotoCondensed-Bold', sans-serif;
                            font-size: 1.3em;
                            text-transform: uppercase;
                        }




                        .content-product {
                            display: none;
                            color: $white;
                            font-family: 'Roboto-Regular', sans-serif;
                            font-size: 1.05em;
                            transition: all 0.2s;

                        }
                    }
                }

                &:hover {
                    .background {
                        .txt {
                            .content-product {
                                display: block;
                                transition: all 0.2s;
                            }
                        }
                    }
                }

                .button {
                    width: 100%;
                    background-color: $dark-blue;
                    padding: 20px;
                    margin-top: -2px;
                    display : flex;
                    justify-content: center;

                    a {
                        display: inline-block;
                        border: 1px solid $orange;
                        padding: 5px 10px;
                        background: none;
                        text-transform: uppercase;
                        color: $orange;
                   
                        margin: auto;
                        transition: all 0.2s;
                        text-decoration: none;

                        &.active,
                        &:hover {
                            text-decoration: none;
                            background-color: $orange;
                            color: $white;
                            transition: all 0.2s;

                        }
                    }
                }
            }
        }
    }
}