footer {
    background-color: $dark-brown;
    padding: 50px 15px;


    .right {
        img {
            width: 100%;
            height: auto;
            max-width: 250px;
        }

        h3 {
            font-family: 'Roboto-Regular', sans-serif;
            font-size: 1.8em;
            color: $green;
            text-transform: uppercase;
            margin-top: 15px;
            margin-bottom: 30px;
        }

        p {
            font-family: 'Roboto-Regular', sans-serif;
        
            font-size: 1.1em;
            color: $white;
        }

        .icon {
            font-size: 1.8em;
        }
    }

    .social-media{
        a{
            text-decoration: none;
        }
    }

    .newsletter-form {

        label {
            font-family: 'Roboto-Bold', sans-serif;
            font-size: 1.7em;
            color: $white;
            display: block;
            margin-bottom: 20px;

        }

        .action-button {
            display: inline-block;
            margin-bottom: 50px;
            border: 1px solid $white;

            .newsletter-email {
                background: none;
                border: none;
                padding: 8px 15px 10px 20px;
                max-width: 199px;
                color: $white;

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $white;
                    opacity: 1;
                    /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: $white;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: $white;
                }
            }

            .newsletter-submit {
                padding: 10px 15px 10px 10px;
                background-color: $green;
                color: $white;
                border: 0px solid $white;

            }
        }
    }

    .actu {
        p {
            color: $white;
            font-family: 'RobotoCondensed-Regular', sans-serif;
            font-size: 1.1em;

            &.green {
                color: $green;
                font-family: 'RobotoCondensed-Bold', sans-serif;

            }

            &.title {
                font-family: 'Roboto-Bold', sans-serif;
                font-size: 1.7em;
                color: $white;
            }
        }
    }

    .plans {
        p {
            font-family: 'Roboto-Bold', sans-serif;
            font-size: 1.7em;
            color: $white;
        }

        ul {
            color: $white;
            font-family: 'Roboto-Bold', sans-serif;
            font-size: 1.1em;
            list-style-type: none;
            padding-left: 0;

            li {
                &:before {
                    content: '- ';
                }

                a {
                    text-decoration: none;
                    color: $white;
                }
            }
        }
    }
}

@media (max-width:767.99px) {
    footer {

        .right {
            text-align: center;
            margin-bottom: 50px;

        }

        .newsletter-form {
            text-align: center;

            label {
                font-family: 'Roboto-Bold', sans-serif;
                font-size: 1.7em;
                color: $white;
                display: block;
                margin-bottom: 20px;

            }

            .action-button {
                display: inline-block;
                margin-bottom: 50px;
                border: 1px solid $white;

                .newsletter-email {
                    background: none;
                    border: none;
                    padding: 8px 15px 10px 20px;
                    max-width: 155px;
                    color: $white;

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: $white;
                        opacity: 1;
                        /* Firefox */
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: $white;
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: $white;
                    }
                }

                .newsletter-submit {
                    padding: 10px 15px 10px 10px;
                    background-color: $green;
                    color: $white;
                    border: 0px solid $white;

                }
            }
        }

        .actu {
            text-align: center;
            margin-bottom: 30px;

            p {
                color: $white;
                font-family: 'RobotoCondensed-Regular', sans-serif;
                font-size: 1.1em;

                &.green {
                    color: $green;
                    font-family: 'RobotoCondensed-Bold', sans-serif;

                }

                &.title {
                    font-family: 'Roboto-Bold', sans-serif;
                    font-size: 1.7em;
                    color: $white;
                }
            }
        }

        .plans {
            text-align: center;

            p {
                font-family: 'Roboto-Bold', sans-serif;
                font-size: 1.7em;
                color: $white;
            }

            ul {
                color: $white;
                font-family: 'Roboto-Bold', sans-serif;
                font-size: 1.1em;
                list-style-type: none;
                padding-left: 0;

                li {
                    &:before {
                        content: '- ';
                    }

                    a {
                        text-decoration: none;
                        color: $white;
                    }
                }
            }
        }
    }
}