.contact {
    padding-bottom : 50px;
    p {
        font-family: 'FiraSans-Regular', sans-serif;
    }

    form {
        .flex {
            display: flex;
            flex-direction: column;
            margin-top : 15px;

            label{
                font-family: 'FiraSans-Bold', sans-serif;
                font-size : 1.1em;
                color : $black;
            }
            ::placeholder{
                font-family: 'Roboto-Regular', sans-serif;
                color : $black;
                font-size : 1em;
                padding-left : 10px;
            }
            input, textarea{
                padding : 10px 20px;
            }

            textarea{
                height : 150px;
            }
        }
    }

    .button-submit{
        background-color: transparent;
        border : 2px solid $orange;
        color : $orange;
        text-transform: uppercase;
        display: block;
        margin :auto;
        margin-top : 30px;
        cursor : pointer;
        transition: all 0.2s;
        margin-bottom : 30px;
        padding : 5px 10px;

        &:hover{
            border : 2px solid $orange;
            color : $white;
            background-color : $orange;
            transition: all 0.2s;
        }
    }


}