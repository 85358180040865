@font-face {
  font-family: 'FiraSans-Bold';
  src: url('fonts/fira_sans/FiraSans-Bold.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/fira_sans/FiraSans-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: 'FiraSans-Regular';
  src: url('fonts/fira_sans/FiraSans-Regular.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/fira_sans/FiraSans-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'RobotoCondensed-Bold';
    src: url('fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf');
    /* IE9 Compat Modes */
    src: url('./fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf') format('truetype'),
      /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'RobotoCondensed-Regular';
    src: url('fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf');
    /* IE9 Compat Modes */
    src: url('./fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'RobotoCondensed-BoldItalic';
    src: url('fonts/Roboto_Condensed/RobotoCondensed-BoldItalic');
    /* IE9 Compat Modes */
    src: url('./fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf') format('truetype'),
      /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Roboto-Bold';
    src: url('fonts/Roboto/Roboto-Bold.ttf');
    /* IE9 Compat Modes */
    src: url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype'),
      /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Roboto-Regular';
    src: url('fonts/Roboto/Roboto-Regular.ttf');
    /* IE9 Compat Modes */
    src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon/icomoon.eot?yhxbde');
    src:  url('fonts/icomoon/icomoon.eot?yhxbde#iefix') format('embedded-opentype'),
      url('fonts/icomoon/icomoon.woff2?yhxbde') format('woff2'),
      url('fonts/icomoon/icomoon.ttf?yhxbde') format('truetype'),
      url('fonts/icomoon/icomoon.woff?yhxbde') format('woff'),
      url('fonts/icomoon/icomoon.svg?yhxbde#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-cross:before {
    content: "\e90d";
    color: #008f30;
  }
  .icon-loop:before {
    content: "\e905";
    color: #00882e;
  }
  .icon-dl:before {
    content: "\e908";
    color: #ffaf10;
  }
  .icon-arrow-back:before {
    content: "\e909";
    color: #008f30;
  }
  .icon-arrow-bread:before {
    content: "\e90a";
    color: #008f30;
  }
  .icon-twitter:before {
    content: "\e906";
    color: #fff;
  }
  .icon-facebook:before {
    content: "\e907";
    color: #fff;
  }
  .icon-marker:before {
    content: "\e900";
    color: #00882e;
  }
  .icon-cube:before {
    content: "\e901";
    color: #fff;
  }
  .icon-rouage:before {
    content: "\e902";
    color: #00882e;
  }
  .icon-tel:before {
    content: "\e903";
    color: #fff;
  }
  .icon-mail:before {
    content: "\e904";
    color: #fff;
  }
  