@font-face {
  font-family: 'FiraSans-Bold';
  src: url("fonts/fira_sans/FiraSans-Bold.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/fira_sans/FiraSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'FiraSans-Regular';
  src: url("fonts/fira_sans/FiraSans-Regular.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/fira_sans/FiraSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'RobotoCondensed-Bold';
  src: url("fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url("fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'RobotoCondensed-BoldItalic';
  src: url("fonts/Roboto_Condensed/RobotoCondensed-BoldItalic");
  /* IE9 Compat Modes */
  src: url("./fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Bold';
  src: url("fonts/Roboto/Roboto-Bold.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("fonts/Roboto/Roboto-Regular.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon/icomoon.eot?yhxbde");
  src: url("fonts/icomoon/icomoon.eot?yhxbde#iefix") format("embedded-opentype"), url("fonts/icomoon/icomoon.woff2?yhxbde") format("woff2"), url("fonts/icomoon/icomoon.ttf?yhxbde") format("truetype"), url("fonts/icomoon/icomoon.woff?yhxbde") format("woff"), url("fonts/icomoon/icomoon.svg?yhxbde#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-cross:before {
  content: "\e90d";
  color: #008f30; }

.icon-loop:before {
  content: "\e905";
  color: #00882e; }

.icon-dl:before {
  content: "\e908";
  color: #ffaf10; }

.icon-arrow-back:before {
  content: "\e909";
  color: #008f30; }

.icon-arrow-bread:before {
  content: "\e90a";
  color: #008f30; }

.icon-twitter:before {
  content: "\e906";
  color: #fff; }

.icon-facebook:before {
  content: "\e907";
  color: #fff; }

.icon-marker:before {
  content: "\e900";
  color: #00882e; }

.icon-cube:before {
  content: "\e901";
  color: #fff; }

.icon-rouage:before {
  content: "\e902";
  color: #00882e; }

.icon-tel:before {
  content: "\e903";
  color: #fff; }

.icon-mail:before {
  content: "\e904";
  color: #fff; }

header {
  position: relative;
  z-index: 2;
  background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.86) 68%, rgba(0, 136, 46, 0.75) 68%, rgba(0, 136, 46, 0.75) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.86) 68%, rgba(0, 136, 46, 0.75) 68%, rgba(0, 136, 46, 0.75) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.86) 68%, rgba(0, 136, 46, 0.75) 68%, rgba(0, 136, 46, 0.75) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

nav {
  padding: 0 15px !important; }
  nav .navbar-brand {
    padding-bottom: 0; }
    nav .navbar-brand img {
      width: 100%;
      height: 100%; }
  nav .navbar-nav .nav-item .nav-link {
    font-family: 'RobotoCondensed-Bold', sans-serif;
    font-size: 0.875em;
    text-transform: uppercase;
    color: #000000;
    padding: 0 30px;
    transition: all 0.2s; }
    nav .navbar-nav .nav-item .nav-link:hover {
      color: #00882E;
      transition: all 0.2s; }
  nav .navbar-nav .nav-item.active .nav-link {
    color: #00882E; }
  nav .flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100px;
    padding-top: 60px; }
  nav .language-dropdown .lang-fr .flag {
    background-image: url("../images/flag-fr.png");
    float: left; }
  nav .language-dropdown .lang-en .flag {
    background-image: url("../images/flag-en.png");
    float: left; }
  nav .language-dropdown .lang-de {
    margin-top: 8px; }
    nav .language-dropdown .lang-de .flag {
      background-image: url("../images/flag-de.png");
      float: left; }
  nav .language-dropdown .selector {
    content: "";
    background-image: url("../images/arrow-down.png");
    width: 20px;
    height: 20px;
    display: block;
    margin-left: 50px;
    background-repeat: no-repeat;
    background-position: center; }
  nav .language-dropdown label,
  nav .language-dropdown ul li {
    display: block;
    width: 50px;
    height: 20px;
    background: #ffffff;
    padding: 0px 6px;
    cursor: pointer; }
  nav .language-dropdown ul.lang-list {
    position: relative;
    visibility: hidden;
    opacity: 0;
    z-index: 1; }
    nav .language-dropdown ul.lang-list li:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px; }
    nav .language-dropdown ul.lang-list li.selected {
      display: none; }
  nav .language-dropdown label {
    position: relative;
    border-radius: 3px;
    z-index: 2; }
  nav .language-dropdown .lang-flag .flag,
  nav .language-dropdown .lang-list .lang .flag {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position: center; }
  nav .language-dropdown .lang-list {
    padding: 5px 0;
    background-color: #ffffff; }
  nav .language-dropdown.open ul.lang-list {
    visibility: visible;
    opacity: 1; }
  nav .language-dropdown.open label {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; }
  nav .info-contact {
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    vertical-align: middle;
    padding-top: 30px; }
    nav .info-contact p {
      margin-left: 20px; }
    nav .info-contact .icon {
      margin-right: 8px;
      font-size: 0.8em; }

@media (max-width: 767.99px) {
  header {
    position: relative;
    z-index: 2;
    background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.86) 78%, rgba(0, 136, 46, 0.75) 78%, rgba(0, 136, 46, 0.75) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.86) 78%, rgba(0, 136, 46, 0.75) 78%, rgba(0, 136, 46, 0.75) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.86) 78%, rgba(0, 136, 46, 0.75) 78%, rgba(0, 136, 46, 0.75) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  nav {
    /*     .navbar-collapse {
      position: absolute;
      top: 98px;
      left: -103px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      width: 100vw;
      height: 100vh;

      background-color: $white;

    }

    .navbar-collapse.collapsing {
      height: auto;
      -webkit-transition: left 0.3s ease;
      -o-transition: left 0.3s ease;
      -moz-transition: left 0.3s ease;
      transition: left 0.3s ease;
      left: -160%;
    }

    .navbar-collapse.show {
      left: -103px;
      -webkit-transition: left 0.3s ease-in;
      -o-transition: left 0.3s ease-in;
      -moz-transition: left 0.3s ease-in;
      transition: left 0.3s ease-in;
    } */ }
    nav .navbar-brand img {
      width: 55%; }
    nav .navbar-nav .nav-item {
      padding: 10px 0; }
      nav .navbar-nav .nav-item .nav-link {
        font-size: 1.3em;
        padding-left: 15px; }
    nav .navbar-toggler {
      border: none; }
    nav .flex {
      padding: 15px 15px 30px 15px;
      height: 100%; }
    nav .info-contact {
      flex-direction: column;
      padding-top: 0; }
    nav .language-dropdown .selector {
      content: "";
      background-image: url(../images/arrow-down.png);
      width: 13px;
      height: 22px;
      display: block;
      margin-left: 41px;
      background-repeat: no-repeat;
      background-position: center; }
    nav .language-dropdown label {
      background: transparent; } }

footer {
  background-color: #333333;
  padding: 50px 15px; }
  footer .right img {
    width: 100%;
    height: auto;
    max-width: 250px; }
  footer .right h3 {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 1.8em;
    color: #00882E;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 30px; }
  footer .right p {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 1.1em;
    color: #ffffff; }
  footer .right .icon {
    font-size: 1.8em; }
  footer .social-media a {
    text-decoration: none; }
  footer .newsletter-form label {
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 1.7em;
    color: #ffffff;
    display: block;
    margin-bottom: 20px; }
  footer .newsletter-form .action-button {
    display: inline-block;
    margin-bottom: 50px;
    border: 1px solid #ffffff; }
    footer .newsletter-form .action-button .newsletter-email {
      background: none;
      border: none;
      padding: 8px 15px 10px 20px;
      max-width: 199px;
      color: #ffffff; }
      footer .newsletter-form .action-button .newsletter-email::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #ffffff;
        opacity: 1;
        /* Firefox */ }
      footer .newsletter-form .action-button .newsletter-email:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ffffff; }
      footer .newsletter-form .action-button .newsletter-email::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #ffffff; }
    footer .newsletter-form .action-button .newsletter-submit {
      padding: 10px 15px 10px 10px;
      background-color: #00882E;
      color: #ffffff;
      border: 0px solid #ffffff; }
  footer .actu p {
    color: #ffffff;
    font-family: 'RobotoCondensed-Regular', sans-serif;
    font-size: 1.1em; }
    footer .actu p.green {
      color: #00882E;
      font-family: 'RobotoCondensed-Bold', sans-serif; }
    footer .actu p.title {
      font-family: 'Roboto-Bold', sans-serif;
      font-size: 1.7em;
      color: #ffffff; }
  footer .plans p {
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 1.7em;
    color: #ffffff; }
  footer .plans ul {
    color: #ffffff;
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 1.1em;
    list-style-type: none;
    padding-left: 0; }
    footer .plans ul li:before {
      content: '- '; }
    footer .plans ul li a {
      text-decoration: none;
      color: #ffffff; }

@media (max-width: 767.99px) {
  footer .right {
    text-align: center;
    margin-bottom: 50px; }
  footer .newsletter-form {
    text-align: center; }
    footer .newsletter-form label {
      font-family: 'Roboto-Bold', sans-serif;
      font-size: 1.7em;
      color: #ffffff;
      display: block;
      margin-bottom: 20px; }
    footer .newsletter-form .action-button {
      display: inline-block;
      margin-bottom: 50px;
      border: 1px solid #ffffff; }
      footer .newsletter-form .action-button .newsletter-email {
        background: none;
        border: none;
        padding: 8px 15px 10px 20px;
        max-width: 155px;
        color: #ffffff; }
        footer .newsletter-form .action-button .newsletter-email::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #ffffff;
          opacity: 1;
          /* Firefox */ }
        footer .newsletter-form .action-button .newsletter-email:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #ffffff; }
        footer .newsletter-form .action-button .newsletter-email::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #ffffff; }
      footer .newsletter-form .action-button .newsletter-submit {
        padding: 10px 15px 10px 10px;
        background-color: #00882E;
        color: #ffffff;
        border: 0px solid #ffffff; }
  footer .actu {
    text-align: center;
    margin-bottom: 30px; }
    footer .actu p {
      color: #ffffff;
      font-family: 'RobotoCondensed-Regular', sans-serif;
      font-size: 1.1em; }
      footer .actu p.green {
        color: #00882E;
        font-family: 'RobotoCondensed-Bold', sans-serif; }
      footer .actu p.title {
        font-family: 'Roboto-Bold', sans-serif;
        font-size: 1.7em;
        color: #ffffff; }
  footer .plans {
    text-align: center; }
    footer .plans p {
      font-family: 'Roboto-Bold', sans-serif;
      font-size: 1.7em;
      color: #ffffff; }
    footer .plans ul {
      color: #ffffff;
      font-family: 'Roboto-Bold', sans-serif;
      font-size: 1.1em;
      list-style-type: none;
      padding-left: 0; }
      footer .plans ul li:before {
        content: '- '; }
      footer .plans ul li a {
        text-decoration: none;
        color: #ffffff; } }

.slider-home {
  margin-top: -55px;
  z-index: 1; }
  .slider-home .owl-carousel .item {
    height: 500px;
    position: relative;
    display: flex;
    align-items: center; }
    .slider-home .owl-carousel .item h2 {
      font-family: "RobotoCondensed-Bold", sans-serif;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 4em;
      padding: 30px 30px 15px 30px; }
    .slider-home .owl-carousel .item .cross {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 32px;
      height: 32px; }
      .slider-home .owl-carousel .item .cross:before, .slider-home .owl-carousel .item .cross:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 4px;
        background-color: #ffffff; }
      .slider-home .owl-carousel .item .cross:before {
        transform: rotate(0deg); }
      .slider-home .owl-carousel .item .cross:after {
        transform: rotate(90deg); }
    .slider-home .owl-carousel .item p {
      color: #FFAF10;
      font-family: "RobotoCondensed-BoldItalic", sans-serif;
      font-size: 1.4em;
      padding: 0 30px 15px 30px;
      text-transform: uppercase; }
  .slider-home .owl-carousel .owl-dots {
    position: absolute;
    bottom: 30px;
    left: 15%; }
    .slider-home .owl-carousel .owl-dots .owl-dot {
      border: 3px solid #ffffff;
      margin-right: 10px; }
      .slider-home .owl-carousel .owl-dots .owl-dot:last-child {
        margin-right: 0; }
      .slider-home .owl-carousel .owl-dots .owl-dot span {
        border-radius: 0;
        background-color: transparent;
        margin: 2px 2px; }
      .slider-home .owl-carousel .owl-dots .owl-dot.active span {
        background-color: #FFAF10; }

.categories-products .list-products {
  list-style: none;
  display: -webkit-flex;
  /* Safari */
  -webkit-flex-wrap: wrap;
  /* Safari 6.1+ */
  display: flex;
  flex-wrap: wrap;
  padding: 0; }
  .categories-products .list-products .category {
    background-repeat: no-repeat;
    background-size: cover;
    width: 20%;
    height: auto;
    padding: 30px 30px; }
    .categories-products .list-products .category h3 {
      color: #ffffff;
      text-transform: uppercase;
      font-family: 'RobotoCondensed-Bold';
      font-size: 1.4em;
      margin-bottom: 50px; }
    .categories-products .list-products .category a {
      color: #FFAF10;
      text-decoration: none;
      font-size: 1.2em;
      border: 2px solid #FFAF10;
      padding: 5px 20px;
      display: inline-block;
      transition: all 0.2s; }
      .categories-products .list-products .category a:hover {
        background-color: #FFAF10;
        color: #ffffff;
        transition: all 0.2s; }

.product-1,
.map {
  padding: 50px 15px; }
  .product-1.map-agents .txt,
  .map.map-agents .txt {
    text-align: center; }
    .product-1.map-agents .txt h2,
    .map.map-agents .txt h2 {
      margin-top: 15px;
      margin-bottom: 15px; }
    .product-1.map-agents .txt p,
    .map.map-agents .txt p {
      margin-bottom: 30px;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 1em;
      color: #000000; }
  .product-1 .txt .icon-rouage,
  .product-1 .txt .icon-marker,
  .map .txt .icon-rouage,
  .map .txt .icon-marker {
    font-size: 3.5em; }
  .product-1 .txt h1,
  .product-1 .txt h2,
  .map .txt h1,
  .map .txt h2 {
    font-family: 'RobotoCondensed-Bold', sans-serif;
    font-size: 1.8em;
    color: #000000;
    padding-left: 30px;
    margin-bottom: 50px; }
    .product-1 .txt h1 .green,
    .product-1 .txt h2 .green,
    .map .txt h1 .green,
    .map .txt h2 .green {
      color: #00882E; }
      .product-1 .txt h1 .green.uppercase,
      .product-1 .txt h2 .green.uppercase,
      .map .txt h1 .green.uppercase,
      .map .txt h2 .green.uppercase {
        text-transform: uppercase; }
  .product-1 .txt p,
  .map .txt p {
    font-size: 1.1em;
    color: #000000;
    font-family: 'Roboto-Regular', sans-serif; }
    .product-1 .txt p .orange,
    .map .txt p .orange {
      font-family: 'Roboto-Bold', sans-serif;
      color: #FFAF10; }
  .product-1 img,
  .map img {
    width: 100%;
    height: auto;
    padding-top: 100px;
    max-width: 350px; }
  .product-1 .part-search,
  .map .part-search {
    padding-left: 0; }
    .product-1 .part-search .search,
    .map .part-search .search {
      border: 8px solid #00882E !important;
      padding: 10px;
      width: 100%; }
    .product-1 .part-search .search-list,
    .map .part-search .search-list {
      list-style: none;
      padding: 0 0px; }
      .product-1 .part-search .search-list li,
      .map .part-search .search-list li {
        margin-bottom: 15px;
        padding: 15px 15px;
        border-bottom: 1px solid #D0E2D5; }
        .product-1 .part-search .search-list li .flex-space p,
        .product-1 .part-search .search-list li .txt-left p,
        .product-1 .part-search .search-list li .txt-right p,
        .map .part-search .search-list li .flex-space p,
        .map .part-search .search-list li .txt-left p,
        .map .part-search .search-list li .txt-right p {
          margin-bottom: 5px; }
        .product-1 .part-search .search-list li .flex-space .pays,
        .product-1 .part-search .search-list li .txt-left .pays,
        .product-1 .part-search .search-list li .txt-right .pays,
        .map .part-search .search-list li .flex-space .pays,
        .map .part-search .search-list li .txt-left .pays,
        .map .part-search .search-list li .txt-right .pays {
          font-family: 'RobotoCondensed-Bold';
          font-size: 1.2em;
          color: #00882E; }
        .product-1 .part-search .search-list li .flex-space .city,
        .product-1 .part-search .search-list li .flex-space .sous-rep,
        .product-1 .part-search .search-list li .flex-space .mail,
        .product-1 .part-search .search-list li .txt-left .city,
        .product-1 .part-search .search-list li .txt-left .sous-rep,
        .product-1 .part-search .search-list li .txt-left .mail,
        .product-1 .part-search .search-list li .txt-right .city,
        .product-1 .part-search .search-list li .txt-right .sous-rep,
        .product-1 .part-search .search-list li .txt-right .mail,
        .map .part-search .search-list li .flex-space .city,
        .map .part-search .search-list li .flex-space .sous-rep,
        .map .part-search .search-list li .flex-space .mail,
        .map .part-search .search-list li .txt-left .city,
        .map .part-search .search-list li .txt-left .sous-rep,
        .map .part-search .search-list li .txt-left .mail,
        .map .part-search .search-list li .txt-right .city,
        .map .part-search .search-list li .txt-right .sous-rep,
        .map .part-search .search-list li .txt-right .mail {
          font-family: 'Roboto-Regular', sans-serif;
          font-size: 0.95em;
          color: #000000; }
        .product-1 .part-search .search-list li .flex-space .sous-rep,
        .product-1 .part-search .search-list li .txt-left .sous-rep,
        .product-1 .part-search .search-list li .txt-right .sous-rep,
        .map .part-search .search-list li .flex-space .sous-rep,
        .map .part-search .search-list li .txt-left .sous-rep,
        .map .part-search .search-list li .txt-right .sous-rep {
          text-transform: uppercase; }
        .product-1 .part-search .search-list li .flex-space .rep,
        .product-1 .part-search .search-list li .flex-space .contact,
        .product-1 .part-search .search-list li .txt-left .rep,
        .product-1 .part-search .search-list li .txt-left .contact,
        .product-1 .part-search .search-list li .txt-right .rep,
        .product-1 .part-search .search-list li .txt-right .contact,
        .map .part-search .search-list li .flex-space .rep,
        .map .part-search .search-list li .flex-space .contact,
        .map .part-search .search-list li .txt-left .rep,
        .map .part-search .search-list li .txt-left .contact,
        .map .part-search .search-list li .txt-right .rep,
        .map .part-search .search-list li .txt-right .contact {
          font-family: 'Roboto-Bold', sans-serif;
          font-size: 0.95em;
          color: #848484;
          padding-bottom: 0; }
        .product-1 .part-search .search-list li .flex-space,
        .map .part-search .search-list li .flex-space {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
        .product-1 .part-search .search-list li .txt-left,
        .map .part-search .search-list li .txt-left {
          text-align: left; }
        .product-1 .part-search .search-list li .txt-right,
        .map .part-search .search-list li .txt-right {
          text-align: right; }
  .product-1 .back-map,
  .map .back-map {
    background-color: #D0E2D5;
    overflow: hidden;
    padding: 0; }
    .product-1 .back-map .icon-corner-top,
    .map .back-map .icon-corner-top {
      position: absolute;
      top: -4px;
      left: -3px;
      border-top: 6px solid #00882E;
      width: 6%;
      height: 10%;
      z-index: 10; }
    .product-1 .back-map .icon-corner-left,
    .map .back-map .icon-corner-left {
      position: absolute;
      top: -4px;
      left: -4px;
      border-left: 6px solid #00882E;
      width: 10px;
      height: 8%;
      z-index: 10; }
    .product-1 .back-map #chartdiv,
    .map .back-map #chartdiv {
      width: 100%;
      height: 425px; }
      .product-1 .back-map #chartdiv [aria-labelledby=id-47-title],
      .map .back-map #chartdiv [aria-labelledby=id-47-title] {
        display: none; }
      .product-1 .back-map #chartdiv [aria-describedby],
      .map .back-map #chartdiv [aria-describedby] {
        color: #ffffff; }
    .product-1 .back-map .map-marker,
    .map .back-map .map-marker {
      /* adjusting for the marker dimensions
            so that it is centered on coordinates */
      margin-left: -8px;
      margin-top: -8px; }
    .product-1 .back-map .map-marker.map-clickable,
    .map .back-map .map-marker.map-clickable {
      cursor: pointer; }
    .product-1 .back-map .pulse,
    .map .back-map .pulse {
      width: 30px;
      height: 30px;
      background: url("../images/marker-map.svg");
      z-index: 10;
      position: absolute;
      background-repeat: no-repeat; }
      .product-1 .back-map .pulse:hover,
      .map .back-map .pulse:hover {
        background: url("../images/marker-map-hover.svg");
        background-repeat: no-repeat; }
    .product-1 .back-map .map-marker .dot,
    .map .back-map .map-marker .dot {
      background: transparent;
      -webkit-border-radius: 60px;
      -moz-border-radius: 60px;
      border-radius: 60px;
      height: 50px;
      width: 50px;
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: 1;
      opacity: 0; }
    .product-1 .back-map .tooltip,
    .map .back-map .tooltip {
      width: 50px;
      height: 50px; }

.slider-product {
  text-align: center;
  padding: 50px 15px; }
  .slider-product .icon-cube {
    font-size: 3.5em; }
  .slider-product h2 {
    color: #ffffff;
    font-size: 2em;
    font-family: 'RobotoCondensed-Bold', sans-serif;
    margin: 10px 0 35px 0; }
  .slider-product .owl-item > div {
    cursor: pointer;
    margin: 6% 8%;
    transition: margin 0.4s ease;
    width: 100%; }
  .slider-product .owl-item.center > div {
    cursor: auto;
    margin: 0;
    width: 100%; }
  .slider-product .owl-item:not(.center) > div {
    opacity: .75; }
  .slider-product .owl-nav {
    display: block !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%); }
    .slider-product .owl-nav button {
      position: relative;
      left: -50%; }
      .slider-product .owl-nav button.owl-next {
        margin-left: 300px; }
      .slider-product .owl-nav button.owl-next, .slider-product .owl-nav button.owl-prev {
        font-size: 2em;
        color: #00882E;
        background-color: #ffffff;
        border-radius: 50%;
        padding: 0px 20px 6px 20px !important; }
  .slider-product .center .item {
    border: 10px solid #ffffff;
    padding: 30px;
    border-radius: 50%; }
  .slider-product .item {
    padding: 50px; }

@media (max-width: 1199.99px) {
  .slider-product .owl-nav {
    display: none !important; } }

@media (max-width: 991.99px) {
  .categories-products .list-products .category {
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%; } }

@media (max-width: 767.99px) {
  .slider-home {
    margin-top: 0; }
  .slider-product .owl-item > div {
    cursor: pointer;
    margin: 6% 8%;
    transition: margin 0.4s ease;
    width: 50%; }
  .slider-product .owl-item.center > div {
    cursor: auto;
    margin: auto;
    width: 40%; }
  .slider-product .owl-item:not(.center) > div {
    opacity: .75; }
  .slider-product .center .item {
    border: 10px solid #ffffff;
    padding: 30px;
    border-radius: 50%; }
  .slider-product .item {
    padding: 50px; }
  .product-1,
  .map {
    padding: 50px 15px; }
    .product-1.map-agents,
    .map.map-agents {
      padding: 0 15px 50px 15px; }
      .product-1.map-agents .txt,
      .map.map-agents .txt {
        text-align: center; }
        .product-1.map-agents .txt h2,
        .map.map-agents .txt h2 {
          margin-top: 15px;
          margin-bottom: 15px; }
        .product-1.map-agents .txt p,
        .map.map-agents .txt p {
          margin-bottom: 30px;
          font-family: "Roboto-Regular", sans-serif;
          font-size: 1em;
          color: #000000; }
    .product-1 .txt .icon-rouage,
    .product-1 .txt .icon-marker,
    .map .txt .icon-rouage,
    .map .txt .icon-marker {
      font-size: 3.5em; }
    .product-1 .txt h1,
    .product-1 .txt h2,
    .map .txt h1,
    .map .txt h2 {
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 1.8em;
      color: #000000;
      padding-left: 30px;
      margin-bottom: 50px; }
      .product-1 .txt h1 .green,
      .product-1 .txt h2 .green,
      .map .txt h1 .green,
      .map .txt h2 .green {
        color: #00882E; }
        .product-1 .txt h1 .green.uppercase,
        .product-1 .txt h2 .green.uppercase,
        .map .txt h1 .green.uppercase,
        .map .txt h2 .green.uppercase {
          text-transform: uppercase; }
    .product-1 .txt p,
    .map .txt p {
      font-size: 1.1em;
      color: #000000;
      font-family: 'Roboto-Regular', sans-serif; }
      .product-1 .txt p .orange,
      .map .txt p .orange {
        font-family: 'Roboto-Bold', sans-serif;
        color: #FFAF10; }
    .product-1 img,
    .map img {
      width: 100%;
      height: auto;
      padding-top: 100px;
      max-width: 350px; }
    .product-1 .part-search,
    .map .part-search {
      padding-left: 0; }
      .product-1 .part-search .search,
      .map .part-search .search {
        border: 8px solid #00882E !important;
        padding: 10px;
        width: 100%; }
      .product-1 .part-search .search-list,
      .map .part-search .search-list {
        list-style: none;
        padding: 0 0px; }
        .product-1 .part-search .search-list li,
        .map .part-search .search-list li {
          margin-bottom: 15px;
          padding: 15px 15px;
          border-bottom: 1px solid #D0E2D5;
          cursor: pointer; }
          .product-1 .part-search .search-list li:last-child,
          .map .part-search .search-list li:last-child {
            border: none; }
          .product-1 .part-search .search-list li .flex-space p,
          .product-1 .part-search .search-list li .txt-left p,
          .product-1 .part-search .search-list li .txt-right p,
          .map .part-search .search-list li .flex-space p,
          .map .part-search .search-list li .txt-left p,
          .map .part-search .search-list li .txt-right p {
            margin-bottom: 5px; }
          .product-1 .part-search .search-list li .flex-space .pays,
          .product-1 .part-search .search-list li .txt-left .pays,
          .product-1 .part-search .search-list li .txt-right .pays,
          .map .part-search .search-list li .flex-space .pays,
          .map .part-search .search-list li .txt-left .pays,
          .map .part-search .search-list li .txt-right .pays {
            font-family: 'RobotoCondensed-Bold';
            font-size: 1.2em;
            color: #00882E; }
          .product-1 .part-search .search-list li .flex-space .city,
          .product-1 .part-search .search-list li .flex-space .sous-rep,
          .product-1 .part-search .search-list li .flex-space .mail,
          .product-1 .part-search .search-list li .txt-left .city,
          .product-1 .part-search .search-list li .txt-left .sous-rep,
          .product-1 .part-search .search-list li .txt-left .mail,
          .product-1 .part-search .search-list li .txt-right .city,
          .product-1 .part-search .search-list li .txt-right .sous-rep,
          .product-1 .part-search .search-list li .txt-right .mail,
          .map .part-search .search-list li .flex-space .city,
          .map .part-search .search-list li .flex-space .sous-rep,
          .map .part-search .search-list li .flex-space .mail,
          .map .part-search .search-list li .txt-left .city,
          .map .part-search .search-list li .txt-left .sous-rep,
          .map .part-search .search-list li .txt-left .mail,
          .map .part-search .search-list li .txt-right .city,
          .map .part-search .search-list li .txt-right .sous-rep,
          .map .part-search .search-list li .txt-right .mail {
            font-family: 'Roboto-Regular', sans-serif;
            font-size: 0.95em;
            color: #000000; }
          .product-1 .part-search .search-list li .flex-space .sous-rep,
          .product-1 .part-search .search-list li .txt-left .sous-rep,
          .product-1 .part-search .search-list li .txt-right .sous-rep,
          .map .part-search .search-list li .flex-space .sous-rep,
          .map .part-search .search-list li .txt-left .sous-rep,
          .map .part-search .search-list li .txt-right .sous-rep {
            text-transform: uppercase; }
          .product-1 .part-search .search-list li .flex-space .rep,
          .product-1 .part-search .search-list li .flex-space .contact,
          .product-1 .part-search .search-list li .txt-left .rep,
          .product-1 .part-search .search-list li .txt-left .contact,
          .product-1 .part-search .search-list li .txt-right .rep,
          .product-1 .part-search .search-list li .txt-right .contact,
          .map .part-search .search-list li .flex-space .rep,
          .map .part-search .search-list li .flex-space .contact,
          .map .part-search .search-list li .txt-left .rep,
          .map .part-search .search-list li .txt-left .contact,
          .map .part-search .search-list li .txt-right .rep,
          .map .part-search .search-list li .txt-right .contact {
            font-family: 'Roboto-Bold', sans-serif;
            font-size: 0.95em;
            color: #848484;
            padding-bottom: 0; }
          .product-1 .part-search .search-list li .flex-space,
          .map .part-search .search-list li .flex-space {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
          .product-1 .part-search .search-list li .txt-left,
          .map .part-search .search-list li .txt-left {
            text-align: left; }
          .product-1 .part-search .search-list li .txt-right,
          .map .part-search .search-list li .txt-right {
            text-align: right; }
    .product-1 .back-map,
    .map .back-map {
      margin-bottom: 30px; } }

@media (max-width: 575.99px) {
  .slider-home {
    z-index: 1; }
    .slider-home .owl-carousel .item h2 {
      font-size: 2em;
      padding: 30px 30px 15px 30px; }
    .slider-home .owl-carousel .item p {
      font-size: 1.4em;
      padding: 0 0px 15px 0px; }
  .categories-products .list-products .category {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%; }
  .product-1,
  .map {
    padding: 50px 15px; }
    .product-1 .txt,
    .map .txt {
      text-align: center; }
      .product-1 .txt .icon-rouage,
      .product-1 .txt .icon-marker,
      .map .txt .icon-rouage,
      .map .txt .icon-marker {
        font-size: 3.5em; }
      .product-1 .txt h1,
      .product-1 .txt h2,
      .map .txt h1,
      .map .txt h2 {
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 1.8em;
        color: #000000;
        padding-left: 30px;
        margin-bottom: 50px; }
        .product-1 .txt h1 .green,
        .product-1 .txt h2 .green,
        .map .txt h1 .green,
        .map .txt h2 .green {
          color: #00882E; }
          .product-1 .txt h1 .green.uppercase,
          .product-1 .txt h2 .green.uppercase,
          .map .txt h1 .green.uppercase,
          .map .txt h2 .green.uppercase {
            text-transform: uppercase; }
      .product-1 .txt p,
      .map .txt p {
        font-size: 1.1em;
        color: #000000;
        font-family: 'Roboto-Regular', sans-serif; }
        .product-1 .txt p .orange,
        .map .txt p .orange {
          font-family: 'Roboto-Bold', sans-serif;
          color: #FFAF10; }
  .slider-product .owl-item > div {
    cursor: pointer;
    margin: 6% 8%;
    transition: margin 0.4s ease;
    width: 50%; }
  .slider-product .owl-item.center > div {
    cursor: auto;
    margin: auto;
    width: 50%; }
  .slider-product .owl-item:not(.center) > div {
    opacity: .75; }
  .slider-product .center .item {
    border: 10px solid #ffffff;
    padding: 30px;
    border-radius: 50%; }
  .slider-product .item {
    padding: 50px; } }

.tooltip-inner {
  background-color: #ffffff;
  color: #000000;
  -webkit-box-shadow: 2px 13px 71px 0px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 2px 13px 71px 0px rgba(0, 0, 0, 0.23);
  box-shadow: 2px 13px 71px 0px rgba(0, 0, 0, 0.23);
  text-align: left;
  padding: 15px; }

.arrow:before {
  border-top-color: #ffffff !important; }

.zoomTest {
  cursor: pointer; }

.header-page {
  margin-top: -55px;
  position: relative; }
  .header-page h1 {
    color: #ffffff;
    font-family: 'RobotoCondensed-Bold', sans-serif;
    text-transform: uppercase;
    font-size: 3.3em;
    margin-top: 160px; }
    .header-page h1:before {
      content: "\e901";
      font-family: 'icomoon';
      font-size: 0.8em;
      margin-right: 20px; }
    .header-page h1.product:before {
      content: ''; }

.breadcrumb-container {
  background-color: rgba(20, 27, 46, 0.1); }
  .breadcrumb-container .breadcrumb {
    padding: 20px 15px;
    margin-bottom: 0; }
    .breadcrumb-container .breadcrumb li {
      color: #000000;
      font-family: 'Roboto-Regular', sans-serif;
      font-size: 1.1em; }
      .breadcrumb-container .breadcrumb li a {
        color: #000000;
        text-decoration: none; }
        .breadcrumb-container .breadcrumb li a:after {
          content: "\e90a";
          color: #00882E;
          margin-right: 10px;
          margin-left: 10px;
          font-family: 'icomoon';
          font-size: 0.7em; }
      .breadcrumb-container .breadcrumb li:last-child {
        font-family: 'Roboto-Bold', sans-serif; }
        .breadcrumb-container .breadcrumb li:last-child a {
          color: #00882E; }
          .breadcrumb-container .breadcrumb li:last-child a:after {
            content: ''; }

.link-back, .link-product {
  margin: 50px 0; }
  .link-back.link-product, .link-product.link-product {
    display: flex;
    justify-content: space-between; }
  .link-back a, .link-product a {
    color: #000000;
    border: 1px solid #00882E;
    border-radius: 30px;
    padding: 10px 15px;
    transition: all 0.2s; }
    .link-back a:hover, .link-product a:hover {
      color: #00882E;
      transition: all 0.2s;
      text-decoration: none; }
    .link-back a span, .link-product a span {
      margin-right: 15px; }
      .link-back a span.icon-dl:before, .link-product a span.icon-dl:before {
        color: #00882E; }

.listing-products .toolbar,
.listing-products .catalogue {
  text-align: right; }
  .listing-products .toolbar a,
  .listing-products .catalogue a {
    display: block;
    text-transform: uppercase;
    font-family: 'RobotoCondensed-Bold', sans-serif;
    color: #000000;
    font-size: 1.3em;
    padding: 10px 25px 10px 0;
    border-right: 2px solid #000000;
    cursor: pointer;
    padding-right: 25px;
    transition: all 0.2s;
    text-decoration: none; }
    .listing-products .toolbar a.active,
    .listing-products .catalogue a.active {
      color: #FFAF10;
      border-color: #FFAF10; }
    .listing-products .toolbar a:hover,
    .listing-products .catalogue a:hover {
      color: #FFAF10;
      border-color: #FFAF10;
      transition: all 0.2s; }

.listing-products .catalogue {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px; }
  .listing-products .catalogue a {
    color: #FFAF10;
    border-color: #FFAF10; }
  .listing-products .catalogue .icon-dl {
    font-size: 3.5em;
    padding-top: 10px; }

.listing-products #list-products {
  list-style: none;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  padding: 0; }
  .listing-products #list-products .one-product {
    margin: 10px; }
    .listing-products #list-products .one-product .background {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 250px;
      height: 240px;
      display: flex; }
      .listing-products #list-products .one-product .background .txt {
        background-color: rgba(20, 27, 46, 0.5);
        align-self: flex-end;
        padding: 20px;
        width: 100%; }
        .listing-products #list-products .one-product .background .txt h2 {
          color: #ffffff;
          font-family: 'RobotoCondensed-Bold', sans-serif;
          font-size: 1.3em;
          text-transform: uppercase; }
        .listing-products #list-products .one-product .background .txt .content-product {
          display: none;
          color: #ffffff;
          font-family: 'Roboto-Regular', sans-serif;
          font-size: 1.05em;
          transition: all 0.2s; }
    .listing-products #list-products .one-product:hover .background .txt .content-product {
      display: block;
      transition: all 0.2s; }
    .listing-products #list-products .one-product .button {
      width: 100%;
      background-color: #141B2E;
      padding: 20px;
      margin-top: -2px;
      display: flex;
      justify-content: center; }
      .listing-products #list-products .one-product .button .details {
        border: 1px solid #FFAF10;
        padding: 5px 20px;
        background: none;
        text-transform: uppercase;
        color: #FFAF10;
        display: inline-block;
        margin: auto;
        transition: all 0.2s;
        text-decoration: none; }
        .listing-products #list-products .one-product .button .details.active, .listing-products #list-products .one-product .button .details:hover {
          background-color: #FFAF10;
          color: #ffffff;
          transition: all 0.2s; }

@media (max-width: 767.99px) {
  .listing-products .toolbar,
  .listing-products .catalogue {
    text-align: center; }
    .listing-products .toolbar a,
    .listing-products .catalogue a {
      display: block;
      text-transform: uppercase;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      color: #000000;
      font-size: 1.3em;
      padding: 10px 25px 10px 0;
      border-right: none;
      cursor: pointer;
      padding-right: 25px;
      transition: all 0.2s; }
      .listing-products .toolbar a.active,
      .listing-products .catalogue a.active {
        color: #FFAF10;
        border-color: #FFAF10; }
      .listing-products .toolbar a:hover,
      .listing-products .catalogue a:hover {
        color: #FFAF10;
        border-color: #FFAF10;
        transition: all 0.2s; }
  .listing-products .catalogue {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .listing-products .catalogue a {
      color: #FFAF10;
      border-color: #FFAF10; }
    .listing-products .catalogue .icon-dl {
      font-size: 1.3em;
      padding-top: 13px;
      margin-right: 10px; }
  .listing-products #list-products {
    list-style: none;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0; }
    .listing-products #list-products .one-product {
      margin: 10px; }
      .listing-products #list-products .one-product .background {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 250px;
        height: 240px;
        display: flex; }
        .listing-products #list-products .one-product .background .txt {
          background-color: rgba(20, 27, 46, 0.5);
          align-self: flex-end;
          padding: 20px;
          width: 100%; }
          .listing-products #list-products .one-product .background .txt h2 {
            color: #ffffff;
            font-family: 'RobotoCondensed-Bold', sans-serif;
            font-size: 1.3em;
            text-transform: uppercase; }
          .listing-products #list-products .one-product .background .txt .content-product {
            display: none;
            color: #ffffff;
            font-family: 'Roboto-Regular', sans-serif;
            font-size: 1.05em;
            transition: all 0.2s; }
      .listing-products #list-products .one-product:hover .background .txt .content-product {
        display: block;
        transition: all 0.2s; }
      .listing-products #list-products .one-product .button {
        width: 100%;
        background-color: #141B2E;
        padding: 20px;
        margin-top: -2px;
        display: flex;
        justify-content: center; }
        .listing-products #list-products .one-product .button a {
          display: inline-block;
          border: 1px solid #FFAF10;
          padding: 5px 10px;
          background: none;
          text-transform: uppercase;
          color: #FFAF10;
          margin: auto;
          transition: all 0.2s;
          text-decoration: none; }
          .listing-products #list-products .one-product .button a.active, .listing-products #list-products .one-product .button a:hover {
            text-decoration: none;
            background-color: #FFAF10;
            color: #ffffff;
            transition: all 0.2s; } }

.product .preview {
  border: 2px solid #00882E;
  background-color: #F4F4F4;
  position: relative;
  margin-bottom: 30px;
  height: 500px; }
  .product .preview .icon-corner-top {
    position: absolute;
    top: -4px;
    left: -3px;
    border-top: 6px solid #00882E;
    width: 10%;
    height: 10%; }
  .product .preview .icon-corner-left {
    position: absolute;
    top: -4px;
    left: -4px;
    border-left: 6px solid #00882E;
    width: 10px;
    height: 10%; }
  .product .preview .icon-corner-bottom {
    position: absolute;
    bottom: -4px;
    right: -3px;
    border-bottom: 6px solid #00882E;
    width: 10%;
    height: 10%; }
  .product .preview .icon-corner-right {
    position: absolute;
    bottom: -4px;
    right: -4px;
    border-right: 6px solid #00882E;
    width: 10px;
    height: 10%; }
  .product .preview .preview-img {
    justify-content: center;
    align-items: center;
    height: 100%; }
  .product .preview .icon-loop {
    position: absolute;
    font-size: 7em;
    color: rgba(0, 136, 46, 0.8);
    top: 50%;
    transform: translateY(-40%);
    left: 50%;
    transform: translateX(-50%);
    margin-top: -54px; }

.product .img {
  border: 2px solid #00882E;
  background-color: #F4F4F4; }
  .product .img img {
    padding: 10px; }

.product h2 {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  color: #00882E;
  font-size: 2em; }

.product p {
  font-family: 'RobotoCondensed-Regular', sans-serif;
  color: #000000;
  font-size: 1.1em; }

.product .nav-product {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  list-style: none;
  border-bottom: 1px solid #D0E2D5; }
  .product .nav-product .item {
    padding: 10px 0px 10px 0;
    font-family: 'RobotoCondensed-Bold', sans-serif;
    font-size: 1.15em; }
    .product .nav-product .item.active {
      border-bottom: 3px solid #00882E; }
      .product .nav-product .item.active .link {
        color: #00882E; }
  .product .nav-product .link {
    position: relative;
    text-transform: uppercase;
    font-size: 1em;
    color: #000000;
    padding: 18px;
    cursor: pointer; }

.product .conteneur-fonctionnement ul {
  font-family: 'RobotoCondensed-Regular', sans-serif;
  font-size: 1.1em;
  color: #00882E;
  padding-top: 30px;
  list-style: none;
  list-style-position: outside;
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAK0lEQVQoU2NkQAcduv/BQhWXGZGlUDhgCToqhFmF4VhUAUa4mwgqHPy+BgAJqRtzyLd0egAAAABJRU5ErkJggg=="); }

.product .conteneur-caracteristiques,
.product .caracteristiques {
  display: none; }
  .product .conteneur-caracteristiques table,
  .product .caracteristiques table {
    width: 100%;
    margin: 50px 0;
    border: 2px solid #00882E; }
    .product .conteneur-caracteristiques table thead,
    .product .caracteristiques table thead {
      font-size: 1.1em;
      text-align: left;
      vertical-align: bottom; }
      .product .conteneur-caracteristiques table thead tr th,
      .product .caracteristiques table thead tr th {
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 1.1em;
        color: #00882E;
        padding: 12px 10px 8px 10px; }
        .product .conteneur-caracteristiques table thead tr th:first-child,
        .product .caracteristiques table thead tr th:first-child {
          border-right: 1px solid #D0E2D5; }
    .product .conteneur-caracteristiques table tbody td,
    .product .caracteristiques table tbody td {
      height: 32px;
      padding: 10px 15px;
      color: #000000;
      border: 1px solid #D0E2D5;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 1em; }

@media (max-width: 767.99px) {
  .product .conteneur-caracteristiques,
  .product .caracteristiques {
    display: none;
    overflow: hidden; }
    .product .conteneur-caracteristiques ul,
    .product .caracteristiques ul {
      font-size: 1em;
      padding-top: 30px; }
    .product .conteneur-caracteristiques table,
    .product .caracteristiques table {
      width: 100%;
      margin: 50px 0;
      display: flex;
      overflow: auto; }
      .product .conteneur-caracteristiques table thead,
      .product .caracteristiques table thead {
        font-size: 0.8em;
        vertical-align: bottom;
        text-align: center; }
        .product .conteneur-caracteristiques table thead tr,
        .product .caracteristiques table thead tr {
          display: flex;
          flex-direction: column; }
          .product .conteneur-caracteristiques table thead tr th,
          .product .caracteristiques table thead tr th {
            height: 40px;
            padding: 12px 10px 8px 10px; }
      .product .conteneur-caracteristiques table tbody,
      .product .caracteristiques table tbody {
        display: flex; }
        .product .conteneur-caracteristiques table tbody tr,
        .product .caracteristiques table tbody tr {
          display: flex;
          flex-direction: column; }
        .product .conteneur-caracteristiques table tbody td,
        .product .caracteristiques table tbody td {
          height: 40px;
          padding: 10px 0px;
          text-align: center;
          font-size: 0.8em; } }

.contact {
  padding-bottom: 50px; }
  .contact p {
    font-family: 'FiraSans-Regular', sans-serif; }
  .contact form .flex {
    display: flex;
    flex-direction: column;
    margin-top: 15px; }
    .contact form .flex label {
      font-family: 'FiraSans-Bold', sans-serif;
      font-size: 1.1em;
      color: #000000; }
    .contact form .flex ::placeholder {
      font-family: 'Roboto-Regular', sans-serif;
      color: #000000;
      font-size: 1em;
      padding-left: 10px; }
    .contact form .flex input, .contact form .flex textarea {
      padding: 10px 20px; }
    .contact form .flex textarea {
      height: 150px; }
  .contact .button-submit {
    background-color: transparent;
    border: 2px solid #FFAF10;
    color: #FFAF10;
    text-transform: uppercase;
    display: block;
    margin: auto;
    margin-top: 30px;
    cursor: pointer;
    transition: all 0.2s;
    margin-bottom: 30px;
    padding: 5px 10px; }
    .contact .button-submit:hover {
      border: 2px solid #FFAF10;
      color: #ffffff;
      background-color: #FFAF10;
      transition: all 0.2s; }

#search-list {
  height: 425px;
  overflow: auto; }

.back-map {
  height: 425px; }
